import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Button } from 'ui/Button/Button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'ui/Form/Form';
import { Input } from 'ui/Input/Input';
import { z } from 'zod';
import { useSubscribeToNewsletterMutation } from '../../features/newsletter/mutations';
import PrivacyCheckBox from '../Form/PrivacyCheckBox/PrivacyCheckBox';
import Alert from '../Layout/Alert/Alert';

const useSchema = () => {
  const { formatMessage } = useIntl();

  return z.object({
    email: z.string().email({ message: formatMessage({ id: 'form_validation_email' }) }),
    termsAndConditions: z
      .boolean()
      .refine((value) => value === true, formatMessage({ id: 'form_validation_required' })),
  });
};

export type FormValuesNewsletterForm = z.infer<ReturnType<typeof useSchema>>;

const NewsletterForm = () => {
  const { formatMessage } = useIntl();

  const [showNewsletterMessage, setShowNewsletterMessage] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);

  const formSchema = useSchema();

  const form = useForm<FormValuesNewsletterForm>({
    resolver: zodResolver(formSchema),
    values: {
      email: '',
      termsAndConditions: false,
    },
  });

  const {
    isError: isErrorSubscribe,
    isPending: isSubmittingSubscribe,
    isSuccess: isSuccessSubscribe,
    mutateAsync: subscribeMutation,
  } = useSubscribeToNewsletterMutation();

  const onSubmit = (values: FormValuesNewsletterForm) => {
    if (values.email && values.termsAndConditions) {
      setShowNewsletterMessage(false);
      setSubscriptionError(false);
      subscribeMutation({ email: values.email });
    }
  };

  useEffect(() => {
    if (isSuccessSubscribe) {
      setShowNewsletterMessage(true);
      setSubscriptionError(false);
    }
    if (isErrorSubscribe) {
      setShowNewsletterMessage(true);
      setSubscriptionError(true);
    }
  }, [isSuccessSubscribe, isErrorSubscribe]);

  return (
    <>
      {showNewsletterMessage && (
        <Alert type={subscriptionError ? 'danger' : 'success'}>
          {formatMessage({
            id: subscriptionError
              ? 'footer_subscribe_newsletter_subscription_error'
              : 'footer_subscribe_newsletter_subscription_success',
          })}
        </Alert>
      )}

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {formatMessage({
                    id: 'footer_newsletter_form_email_placeholder',
                  })}
                </FormLabel>
                <FormControl>
                  <Input {...field} type="email" aria-label={formatMessage({ id: 'newsletter_form_input_email' })} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <PrivacyCheckBox
            form={form}
            termsAndConditions={formatMessage({
              id: 'footer_newsletter_form_accept_terms_and_conditions',
            })}
            termsAndConditionsLink={formatMessage({
              id: 'footer_newsletter_form_terms_and_conditions_link',
            })}
          />

          <Button
            className="w-full"
            variant="secondary"
            disabled={isSubmittingSubscribe}
            loading={isSubmittingSubscribe}
            type="submit"
          >
            {formatMessage({
              id: 'newsletter_block_btn_text',
            })}
          </Button>
        </form>
      </Form>
    </>
  );
};

export default NewsletterForm;
