import CMSSlot from 'components/CMS/CMSSlot/CMSSlot';
import Loader from 'components/Layout/Loader/Loader';
import Modal from 'components/Layout/Modal/Modal';
import { FormValuesNewsletterForm } from 'components/NewsletterForm/NewsletterForm';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import StaticPageTranslations from 'i18n/staticPages';
import { MouseEventHandler, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getCMSPage, getCmsPageFromState } from 'redux/modules/cms';
import { Checkbox } from 'ui/Checkbox/Checkbox';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from 'ui/Form/Form';

const CMS_PAGE_QUALIFIER = 'privacyPolicy';

interface PrivacyCheckBoxProps {
  form: UseFormReturn<FormValuesNewsletterForm>;
  termsAndConditions: string;
  termsAndConditionsLink: string;
}

const PrivacyCheckBox = ({ form, termsAndConditions, termsAndConditionsLink }: PrivacyCheckBoxProps) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { locale } = router;
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const qualifier = StaticPageTranslations[CMS_PAGE_QUALIFIER][locale];

  const cmsPage = useSelector((state) => getCmsPageFromState(state, qualifier, undefined, undefined));

  const handlePrivacyPolicyClick: MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();

    if (!cmsPage) {
      setIsLoading(true);

      try {
        await dispatch(getCMSPage(qualifier, locale, false, false));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        return;
      }
    }

    setShowPrivacyPolicyModal(true);
  };

  const privacyLabel = (
    <>
      {termsAndConditions}{' '}
      <a onClick={handlePrivacyPolicyClick} role="button" tabIndex={0} className="underline">
        {termsAndConditionsLink}
      </a>
    </>
  );

  return (
    <>
      <Modal closeModal={() => setShowPrivacyPolicyModal(false)} show={showPrivacyPolicyModal}>
        <Loader show={isLoading} />
        {cmsPage?.slots?.map((slot, index) => <CMSSlot key={slot.slotId} cmsPage={cmsPage} position={index + 1} />)}
      </Modal>

      <FormField
        control={form.control}
        name="termsAndConditions"
        render={({ field }) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0">
            <FormControl>
              <Checkbox checked={field.value} onCheckedChange={field.onChange} />
            </FormControl>
            <div className="space-y-1 leading-none">
              <FormLabel>{privacyLabel}</FormLabel>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
    </>
  );
};

export default PrivacyCheckBox;
